@font-face {
  font-family: "math";
  src: local("math"), url(../fonts/PTSerif-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "math-regular";
  src: local("math-regular"),
    url(../fonts/PTSerif-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "FrederickatheGreat-Regular";
  src: local("FrederickatheGreat-Regular"),
    url(../fonts/FrederickatheGreat-Regular.ttf) format("truetype");
}

.linehd-5 {
  line-height: 5px !important;
}

.color-green {
  background-color: #56c760 !important;
}

.light-color-green {
  /* background-color: #7ade83 !important; */
  background-color: #b9ce79 !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.card-min-hight {
  min-height: 280px;
}

.ds-work-progress {
  height: auto;
  /* background-color: #3da082; */
  background-color: #7fdfc2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.work-progress-content {
  flex: 1;
  padding: 10px 0px 10px 40px;
}

.work-progress-title {
  padding-bottom: 15px;
}

.work-progress-title h4 {
  /* color: #ffffff; */
  color: #227159;
  margin: 0px;
  font-size: 24px;
  font-weight: 400;
}

.work-progress-title p {
  /* color: #72eac6; */
  color: #227159;
  margin: 0px;
  font-size: 15px;
  font-weight: 100;
  margin-top: -10px;
}

.work-progress-section {
  display: flex;
}

.work-progress-section .active .work-progress-item-thum i.fa-solid {
  /* color: #6cf1c9; */
  color: #227159;
}

.work-progress-section .active .work-progress-item-text p {
  /* color: #6cf1c9; */
  color: #227159;
  font-weight: 500;
}

.work-progress-item {
  display: flex;
  flex-direction: column;
  width: 80px;
}

.work-progress-item-thum {
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-progress-item-thum i.fa-solid {
  color: #aeb3b2;
  font-size: 35px;
}

.work-progress-item-text {
  padding: 0px 2px;
}

.work-progress-item-text p {
  margin: 0px;
  text-align: center;
  /* color: #fff; */
  color: #227159;
  font-size: 10px;
  font-weight: 100;
  line-height: 14px;
  padding-top: 10px;
}

.work-progress-status-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}

.work-progress-status {
  width: 120px;
  height: 120px;
  background-color: #0b3e3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.work-progress-status h4 {
  color: #e7bc00;
  margin: 0px;
  font-size: 36px;
}

.work-progress-status p {
  color: #ffffff;
  margin: 0px;
  font-size: 16px;
  font-weight: 100;
  margin-top: -10px;
}

.work-progress-space {
  width: 75px;
  height: 80px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-progress-space-bar {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}

.td-history {
  min-height: 280px;
  background-color: #b3aa98;
}

.td-history-title {
  justify-content: flex-start !important;
  align-items: flex-start;
}

.td-history-title h4 {
  color: #ffffff;
  margin: 0px;
  font-weight: 400;
  font-size: 20px;
}

.td-history-title p {
  color: #8e180a;
  margin: 0px;
  font-size: 15px;
  font-weight: 100;
  margin-top: -8px;
}

.history-like-btn {
  width: 30px;
  height: 30px;
  /* border-radius: 50px; */
  border: none;
  font-size: 18px;
  color: #2d637b;
  background-color: transparent;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-right:10px; */
}

.history_liked {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
  color: #2d637b;
  background-color: transparent;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-right:10px; */
}

.td-history-list {
  position: relative;
  padding: 0px 10px 1.25rem;
  max-height: 200px;
  overflow: hidden;
  width: 78%;
}

.history-items-list ul {
  margin: 0px;
  padding: 0px;
}

.history-items-list li {
  list-style: none;
  margin-bottom: 10px;
}

.history-items-list.sliding-now {
  animation: up 1s linear infinite forwards;
  animation-delay: 1s;
}

.history-items-list h5 {
  margin: 0px;
  color: #8e180a;
}

.history-items-list p {
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  line-height: 20px;
  white-space: normal;
}

.history-icon {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.history-icon img {
  width: 120px;
}

.td-history-date {
  width: 90px;
  height: 45px;
  /* background-color: #0b3e3f; */
  background-color: #005274;
  position: absolute;
  top: 0px;
  right: 50px;
}

.history-date-container {
  position: relative;
  width: 90px;
}

.history-date-text {
  width: 90px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.history-date-text h5 {
  margin: 0px;
  color: #e7bc00;
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 5px;
}

.history-date-text h6 {
  margin: 0px;
  color: #e7bc00;
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 5px;
}

.history-date-text p {
  margin: 0px;
  color: #ffffff;
  font-size: 30px;
  margin-top: -15px;
}

.arrow-view {
  position: absolute;
  top: 45px;
  left: 0px;
  /* border-top: 30px solid #0b3e3f; */
  border-top: 30px solid #005274;
  border-left: 40px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid transparent;
}

.my-progress {
  padding: 10px;
  background-color: #e7ba00;
  /* min-height: 370px; */
}

.my-progress-title {
  margin-bottom: 10px;
  padding: 10px 25px 0px 25px;
}

.my-progress-title h4 {
  margin: 0px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
}

.my-progress-title p {
  margin: 0px;
  color: #e74d00;
  margin-top: -10px;
  font-size: 14px;
  font-weight: 100;
}

.my-progress-bar-content {
  display: flex;
}

.my-progress-chart {
  width: 180px;
  text-align: center;
}

.my-progress-chart h4 {
  margin: 0px;
  color: #000000;
  padding-top: 15px;
  font-size: 15px;
}

.progress-more-btn {
  /* margin-top: 65px; */
  display: flex;
  flex-direction: row;
  height: 60px;
  /* border:1px solid red; */
}

.progress-more-btn a {
  padding: 5px 10px;
  background-color: #e74d00;
  color: #e7ba00;
  display: table;
  margin: auto;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.progress-more-btn .details_btn {
  padding: 5px 10px;
  background-color: #dddbda;
  color: #000000;
  display: table;
  margin: auto;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.cus-calendar .card-body {
  /* min-height: 370px; */
  padding: 20px;
}

.cus-calendar-title h4 {
  margin: 0px;
  color: #2e637b;
  font-size: 22px;
  font-weight: 500;
}

.cus-calendar-title p {
  color: #3da082;
  font-size: 14px;
  font-weight: 100;
  margin-top: -8px;
}

.process-bar {
  background-color: #58bad7;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px;
}

.process-bar-profile {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #70c8e2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.process-bar-profile h3 {
  font-size: 50px;
  color: #434851;
  font-weight: 100;
}

.process-bar-content {
  flex: 1;
}

.process-purchase {
  margin: 20px 0px;
}

.process-purchase-content {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.process-purchase-content h5 {
  margin: 0px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
}

.process-purchase-content p {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  color: #434851;
}

.slash-bar {
  width: 2px;
  height: 10px;
  background-color: #ffffff;
  margin: 0px 15px;
}

/***************overwrite-css*****************/
.card-calendar {
  background-color: #e6e6e6;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ffffff !important;
  border: 1px solid #3da082;
  border-bottom: 5px solid #3da082;
}

.home-calendar .react-calendar .react-calendar__tile:hover {
  color: #000000 !important;
}

.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
  color: #000000;
}

.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText,
.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText--from {
  color: #000000;
}

.home-calendar .react-calendar .react-calendar__month-view__weekdays {
  color: #000000;
}

.home-calendar .react-calendar .react-calendar__month-view__days__day {
  color: #000000;
}

.home-calendar button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.progress {
  background-color: #434851;
}

.bg-lightgray {
  background-color: #c8c8c8;
}

/* exam list  ========================*/
.exam_list_container {
  display: flex;
  flex: 1;
  /* height: 100vh; */
  justify-content: center;
  /* align-items: center; */
}

.exam_inside_container {
  display: flex;
  flex-direction: column;
}

.contain_container {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 500px;
  height: 100px;
  /* margin-bottom: 40px; */
  overflow: hidden;
  align-items: center;
}

.sub_contain_container {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 500px;
  height: 100px;
  overflow: hidden;
  align-items: center;
}

.green_background {
  /* background-color: #56c760; */
  background-color: #94ac4b !important;
}

.redText {
  color: red;
}

.green_background_border {
  /* background-color: #56c760; */
  border: 4px solid #94ac4b !important;
}

.green_background_one {
  /* background-color: #57c761; */
  background-color: #90b817;
}

.blue_background {
  background-color: #245c75;
}

.orange_background {
  /* background-color: #eb7b15; */
  background-color: #64bfd1;
}

.not_visit_color {
  background-color: #64bfd1;
}

.text_blue {
  color: #245c75;
  text-transform: capitalize;
}

.text_black {
  color: #101010;
}

.question_no_container .btn-warning:hover {
  color: #000;
  background-color: #245c75 !important;
  border-color: #245c75 !important;
}

.left_container {
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 0px 10px 0px 15px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.left_image {
  width: 50px;
  position: relative;
  bottom: -2px;
}

.new_icon_left_image {
  width: 38px;
  position: relative;
  /* bottom: -2px; */
}

.new_icon_left_image_scholastic {
  width: 68px;
  position: relative;
  /* bottom: -2px; */
}

.blue_left_image {
  width: 60px;
  position: relative;
  right: -7px;
  bottom: -8px;
}

.middle_container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.middle_inside_container {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.middle_inside_container h3 {
  text-transform: uppercase;
  margin: 0px;
}

.middle_inside_container p {
  line-height: 15px;
  margin: 0px;
}

.competitive_academic_course {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
}

.bottom_inside_container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 2px;
}

.right_container {
  display: flex;
  flex: 0.2;
  justify-content: flex-end;
  align-items: center;
  /* border:1px solid red; */
}

.right_image {
  width: 70px;
  position: relative;
  /* left: 35px; */
  /* left:26px; */
  /* top: 2px; */
  opacity: 0.2;
}

.new_icon_right_image {
  width: 50px;
  position: relative;
  right: 10px;
  /* left: 35px; */
  /* left:26px; */
  /* top: 2px; */
  opacity: 0.2;
}

.new_icon_right_image_scholastic {
  width: 80px;
  position: relative;
  right: 0px;
  opacity: 1;
}

.blue_right_image {
  width: 80px;
  position: relative;
  right: -24px;
  bottom: -10px;
  height: 70px;
}

.font_white {
  color: #ffffff;
}

.screen_wise_font_size {
  font-size: 150%;
}

.screen_wise_font_size_exam_date {
  font-size: 90%;
}

.dlabnav .metismenu>li.mm-active>a {
  background: #2d637b !important;
  color: #ffffff;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #ffffff;
}

.dlabnav .metismenu ul a:hover,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a.mm-active {
  /* text-decoration: none; */
  color: #2d637b;
}

/* Exam Details ====================== */
.card.green_container {
  /* background-color: #57c761 !important; */
  background-color: #e4f8e6 !important;
}

.card.green_container .dropup,
.dropend,
.dropdown,
.dropstart {
  /* position: relative; */
  width: 100%;
}

.card.green_container .btn-primary-c,
/* .btn.btn-success, */
.btn.btn-secondary,
.btn.btn-warning,
.btn.btn-primary,
/* .btn.btn-danger, */
.btn.btn-info {
  color: #0c5576;
  width: 100%;
  font-size: 16px;
  /* background-color: #fff; */
  border: none;
  text-align: left;
}

/* .card.green_container .dropdown-toggle::after {
    float: right;
    position: relative;
    top:10px;
} */
.card.green_container .basic-dropdown {
  margin-bottom: 10px;
  /* height:80px; */
  /* box-sizing: border-box; */
  /* border:1px solid red; */
}

.card.green_container .dropdown-menu.show {
  width: 100%;
}

.card.green_container .btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #0c5576;
  background-color: #ffffff;
  border-color: #ff796a;
}

.select_branch_list {
  width: 100%;
  padding: 10px;
  margin-bottom: 2px;
  color: #0c5576;
  width: 100%;
  font-size: 16px;
  background-color: #fff;
  border: none;
  text-align: left;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 600;
}

.select_branch_list_name {
  width: 100%;
  height: 462px;
  /* background-color: #ffffff; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow-x: hidden;
  background-color: #fff;
}

.select_branch_list_name {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.select_branch_list_name::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.select_option {
  color: #0c5576;
  width: 100%;
  font-size: 16px;
  background-color: #fff;
  border: none;
  text-align: left;
  font-weight: 600;
  font-family: inherit;
}

.exam select option {
  /* color: #0c5576; */
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.exam select option:disabled {
  /* color: #909090; */
  color: #cbc6c6;
}

/* select option:hover,
select option:focus,
select option:active {
    background: linear-gradient(#000000, #000000);
    background-color: #000000 !important;
    
    color: #ffed00 !important;
}

select option:checked {
    background: linear-gradient(#d6d6d6, #d6d6d6);
    background-color: #d6d6d6 !important;
    
    color: #000000 !important;
} */

.exam {
  position: relative;
}

.drop_down_arrow {
  float: right;
  position: absolute;
  top: 17px;
  right: 15px;
  color: #0c5576;
  font-size: 13px;
  pointer-events: none;
}

.drop_down_arrow_disable {
  float: right;
  position: absolute;
  top: 10px;
  right: 15px;
  color: #0c5576;
  font-size: 13px;
  pointer-events: none;
}

.branch_list_container {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 10px 10px 20px;
  color: #c7c7c7;
  font-size: 14px;
  margin: 1px 0;
  background-color: #ffffff;
  cursor: default;
  border-bottom: 1px solid #57c761;
}

.branch_selected {
  color: #0c5576;
  background-color: #f1f1f1;
}

.padding_15 {
  padding: 15px;
}

.round_8 {
  border-radius: 8px;
}

.font_16 {
  font-size: 16px;
}

.font_13 {
  font-size: 13px;
}

.font_white {
  color: #ffffff;
}

.botton_continer {
  height: 470px;
  border: 4px solid #57c761;
  margin-top: 15px;
  overflow: auto;
}

.botton_continer_scholastic {
  height: 470px;
  border: 4px solid #57c761;
  margin-top: 15px;
  overflow: auto;
}

.botton_continer_competitive {
  height: 470px;
  border: 4px solid #57bad7;
  margin-top: 15px;
  overflow: auto;
}

.botton_continer {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.botton_continer::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.botton_continer_scholastic {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.botton_continer_scholastic::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.color_gery_1,
.color_gery_11 {
  /* color: #afafaf; */
  color: #959595;
}

.color_done {
  /* color: #e6e6e6; */
  color: #bfbdbd;
}

.color_blue_1 {
  /* color:#58bad7; */
  color: #2d6179;
}

.attempt_text {
  font-size: 14px;
}

.font_open_sans {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.margin_buttom_20 {
  margin-bottom: 20px;
}

.margin_buttom_30 {
  margin-bottom: 30px;
}

.margin_buttom_40 {
  margin-bottom: 40px;
}

.font_weight_600 {
  font-weight: 600;
}

.font_weight_900 {
  font-weight: 900;
}

.top_back_container {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
  font-weight: 600;
  margin-bottom: 20px;
  cursor: pointer;
}

.top_back_container_overall {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
  font-weight: 600;
  /* margin-bottom: 20px; */
  cursor: pointer;
}

.overall_right_heading {
  /* width: 100px; */
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
  font-weight: 600;
  /* margin-bottom: 20px; */
  /* cursor: pointer; */
}

.top_back_container_pdf {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
  font-weight: 600;
  cursor: pointer;
}

.back_arrow {
  color: #2d6179;
  font-size: 18px;
  margin-right: 5px;
  font-weight: bold;
}

.chapters_listing:hover .color_gery_1 {
  color: #2d6179;
}

/* .chapters_listing_done{
    background-color: #e6e6e6;
    padding-left:10px;
} */

body {
  background-color: #e6c696;
  /* // e6c696 */
  background-image: url("../images/light_background_1.png");
  /* background-image: url("../images/score_background.png");  */
  /* background-image: url("../images/yellow_background.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.ellipse {
  display: none;
}

/* ///////////DemoExamStart///////////// */
.exam_start_top_container {
  /* padding:10px; */
  background-color: #fff;
  margin: 15px;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  /* width:100%; */
  padding: 10px;
}

.exam_start_logo {
  width: 130px;
}

.right_side_top_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.right_indidual_contaoiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.right_side_icon_size {
  width: 50px;
}

.timer_ounter {
  display: flex;
  /* flex: 1; */
  width: 130px;
  height: 45px;
  border: 1px solid #245c75;
  border-radius: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.watch {
  width: 30px;
}

.time_counter {
  font-size: 22px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 5px;
  position: absolute;
  right: 9px;
}

.answer_container_parent {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  /* height: 575px; */
  /* height: auto; */
  /* border:2px solid red; */
  height: calc(100vh - 144px);
}

.answer_container {
  display: flex;
  flex: 1;
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  margin-right: 15px;
  overflow: hidden;
  /* border:2px solid green; */
  flex-direction: column;
  justify-content: space-between;
}

.question_no_container {
  display: flex;
  flex: 0.3;
  background-color: #ffffff;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;
  flex-direction: column;
}

.exam_start_middle_container {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
}

.body_container {
  background-image: url("../images/yellow_background.png");
  background-position: center center;
  background-size: cover;
  /* position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; */
  height: 100%;
  padding: 10px;
  /* min-height: 723px; */
}

.display_question_number {
  display: flex;
  height: 52px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.view_question_number_container {
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.left_arrow_container {
  margin-right: 10px;
  font-size: 25px;
}

.right_arrow_container {
  margin-left: 10px;
  font-size: 25px;
}

.question_display {
  font-size: 14px;
  align-self: center;
  color: #fff;
  /* align-items: center; */
  position: relative;
  top: -4px;
}

.count_number {
  font-size: 25px;
  align-self: center;
  position: relative;
  top: 4px;
}

.answer_top_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 20px 0 0 0; */
  margin: 20px;
  border: 1px solid #c6c6c6;
  /* height: 100%; */
  border-radius: 10px;
}

.question_heading {
  /* max-height: 200px;
    min-height:70px; */
  padding: 0 20px 0 20px;
  /* font-family: 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* font-family: 'math' !important; */
  font-family: "math-regular" !important;
  font-weight: 900;
  font-size: 20px !important;
  /* display: flex;
    flex: 1; */
  flex-direction: column;
  font-size: 18px;
  overflow: auto;
  /* margin-bottom: 10px; */
  min-height: 70px;
  height: 250px;
  color: #101010;
}

.handler {
  height: 8px;
  padding: 0;
  cursor: ns-resize;
  /* background: #ccc; */
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  position: relative;
}

.handler::after {
  content: "";
  /* border:1px dotted #000; */
  position: absolute;
  width: 120px;
  height: 12px;
  background: #a4a4a4;
  top: 3px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.handler::before {
  content: "";
  border: 1px dotted #858585;
  position: absolute;
  width: 100%;
  top: 2px;
}

.question_heading img {
  align-self: flex-start;
}

.question_heading {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.question_heading::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.question_image {
  width: 350px;
  margin-top: 10px;
}

.asnwer_image_long_width {
  width: 55%;
}

.asnwer_image_square {
  width: 150px;
  height: 150px;
}

.asnwer_image_long_height {
  width: 32%;
}

.asnwer_image {
  width: 120px;
}

.checkbox_from_container {
  overflow: auto;
  flex: 1;
  min-height: 70px;
}

.checkbox_container {
  /* height:200px; */
  /* max-height: 200px; */
  max-height: 250px;
  min-height: 70px;
  /* min-height: 200px; */
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  justify-content: space-between;
  /* border-top: 1px solid #c6c6c6; */
  /* overflow: auto; */
  /* position: relative; */
}

.checkbox_container {
  /* -ms-overflow-style: none !important; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none !important; */
  /* Firefox */
}

.checkbox_container::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.answer_top_container {
  padding-top: 20px;
  /* height:450px; */
  height: 260px;
}

.answer_top_container .form-check-input:checked {
  background-color: #245c75;
  border-color: #c6c6c6 !important;
}

.answer_top_container .form-check-input {
  background-color: #245c75;
  border-color: #c6c6c6 !important;
}

.answer_top_container .form-check-label {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  color: #245c75;
}

.select_question_number {
  height: 40px;
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  overflow: auto;
}

.select_question_number {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.select_question_number::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.inside_select_question_number {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.count_status_question {
  display: flex;
  height: 80px;
  justify-content: center;
  flex-direction: row;
}

.count_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.question_number {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 41px;
  height: 21px;
  border-radius: 3px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  margin-right: 4px;
  margin-bottom: 4px;
}

.question_number:hover {
  /* box-shadow: inset 0 0 10px #f8a100; */
  background-color: #8b0d0e;
}

.que_number {
  color: #fff;
  font-size: 11px;
}

.question_number:hover .que_number {
  font-weight: bold;
}

.bottom_text {
  color: #fff;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  margin-top: 40px;
}

.modal_title {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.modal_body {
  width: 100%;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  /* font-weight: bold; */
  color: #000;
  margin: 5px 0;
  padding-left: 14px;
  position: relative;
}

.modal_body::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #000;
  border-radius: 50px;
  margin-right: 5px;
  position: absolute;
  top: 5px;
  left: 0;
}

.modal_footer_container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.modal_footer_right_container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.modal_footer_button,
.modal_footer_button1 {
  padding: 10px !important;
}

.modal_footer_left_container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.modal_footer_right_container {
  display: flex;
  flex: 1;
}

.footer_text {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  line-height: 12px !important;
  margin-bottom: 2px;
  font-weight: bold;
}

.modal_footer_button,
.modal_footer_button1 {
  height: 40px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  /* flex: 1; */
}

.modal_footer_right_container .modal_footer_button {
  width: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_footer_right_container .modal_footer_button1 {
  width: 160px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_footer_container_info {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.modal_footer_container_info .modal_footer_right_container {
  display: flex;
  justify-content: flex-end;
}

.exam_branch_list_container {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 10px 10px 20px;
  color: #c7c7c7;
  font-size: 14px;
  margin: 1px 0;
  background-color: #ffffff;
  /* cursor: default; */
  border-bottom: 1px solid #57c761;
}

.box_height_547 {
  height: 547px;
}

.unselect_text {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.flex-1 {
  display: flex;
  flex: 1;
}

/****************debasis************* */

.demo-elibrary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.elibrary-branchlist-section {
  background-color: #4ba5c0;
  padding: 15px 15px 35px 15px;
}

.elibrary-branchlist {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
}

.elibrary-branchlist-title {
  background-color: #fff;
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid #4ba5c0;
}

.elibrary-branchlist-title h4 {
  font-size: 15px;
  margin: 0px;
  color: #2e637b;
  font-weight: 500;
}

.elibrary-branchlist-item {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.elibrary-branchlist-item ul {
  margin: 0px;
}

.elibrary-branchlist-item ul li {
  padding: 12px 20px;
  border-top: 1px solid #3da082;
  text-transform: capitalize;
  font-size: 14px;
  color: #b9b9ba;
  cursor: pointer;
}

.elibrary-branchlist-item ul li:hover {
  background-color: #f1f1f1;
  color: #2e637b;
}

.elibrary-branchlist-map-section {
  padding: 15px;
}

.elibrary-branchlist-map-header {
  background-color: #4ba5c0;
  padding: 15px 20px;
  border-radius: 10px;
}

.elibrary-branchlist-map-header h4 {
  font-size: 15px;
  margin: 0px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
}

.elibrary-branchlist-map-content {
  border: 2px solid #4ba5c0;
  margin-top: 10px;
  height: 300px;
  border-radius: 10px;
  overflow-y: auto;
}

.elibrary-branchlist-map-content ul {
  margin: 20px 0px 0px 0px;
}

.elibrary-branchlist-map-content ul li {
  list-style: none;
  margin: 20px 20px;
}

.elibrary-branchlist-map-content ul li a {
  font-weight: 500;
}

.elibrary-branchlist-map-content ul li a span {
  display: block;
  font-weight: 400;
}

.elibrary-branchlist-map-content ul li a:hover {
  color: #4ba5c0;
}

.elibrary-branchlist-map-content ul li a:hover span {
  color: #777777;
}

.exam-container {
  width: 100%;
  height: 100vh;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.lbray-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: #ffffff;
  border-radius: 10px;
}

.exam-library-header {
  margin-bottom: 20px;
}

.exam-library-content {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
}

.exam-library-footer p {
  color: #ffffff;
  text-align: center;
  padding-top: 5px;
  font-size: 10px;
}

.lbray-lft-section a {
  padding: 0px 20px;
  display: flex;
  align-items: center;
}

.lbray-lft-section a i.fa-solid {
  color: #2e637b;
  font-size: 24px;
}

.lbray-lft-section a span {
  color: #2e637b;
  font-size: 12px;
  font-weight: 600;
  padding-left: 10px;
}

.lbray-cntr-section img {
  width: 100px;
}

.lbray-rght-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.image-thum-item a {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
}

.image-thum-item a img {
  width: 50px;
}

.image-thum-item a span {
  display: block;
  margin-top: -5px;
  font-size: 12px;
  color: #2e637b;
  font-weight: 600;
}

.flex-1 {
  display: flex;
  flex: 1;
}

/* Radio css */
.modern-radio-container {
  margin: 7px 0;
  display: flex;
  cursor: pointer;
  user-select: none;
  /* align-items: center; */
}

.radio-outer-circle {
  width: 20px;
  height: 20px;
  min-width: 18px;
  min-height: 18px;
  /* border: 2px solid #245c75; */
  border: 2px solid #ebebeb;
  /* background-color: #245c75; */
  background-color: #ebebeb;
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
  position: relative;
  top: 3px;
}

.radio-inner-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  /* background-color: #0d5bc6; */
  transition: all 0.1s linear;
}

/* .unselected {
    border: 2px solid #666;
} */

.unselected-circle {
  width: 0;
  height: 0;
}

.helper-text {
  padding-right: 8px;
  font-family: "math-regular" !important;
  font-size: 18px !important;
  /* color: #245c75; */
  color: #101010;
}

.helper-text div p span {
  font-family: "math-regular" !important;
  font-size: 18px !important;
  color: #101010 !important;
  font-weight: 900 !important;
}

.check_mark {
  font-size: 20px;
  font-weight: bold;
  /* color: #fff; */
  color: #000;
}

.timer_container {
  scale: 20%;
  width: 70px;
  position: absolute;
  left: -18px;
}

.clock_icon {
  scale: 300%;
  color: #245c75;
}

.active_pointer_event {
  cursor: pointer;
}

.login_button_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.singin_container,
.guest_login_container {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.btn_text {
  color: #fff !important;
  width: 100%;
  font-size: 13px !important;
  border: none;
  text-align: center !important;
}

.date_picker_input {
  background: #fff;
  color: #6e6e6e;
  line-height: 2.4;
  font-size: 1rem;
  border-radius: 0.625rem;
}

.select_option_gender {
  color: #0c5576;
  width: 100%;
  font-size: 16px;
  background-color: #fff;
  border: none;
  text-align: left;
  /* font-weight: 600; */
}

.select_option_gender:active,
.select_option_gender:focus {
  border-color: red;
}

/**************subscribe-tab************/
.subscribe-tab {
  margin-bottom: 40px;
}

.subscribe-tab .nav-tabs {
  border-bottom: 1px solid #9da0a3;
}

.subscribe-tab .nav {
  justify-content: flex-end;
}

.subscribe-tab .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

.subscribe-tab .nav-link:focus,
.subscribe-tab .nav-link:hover,
.subscribe-tab .nav-link.active {
  color: #495057;
  background-color: transparent;
  border-color: #777777;
  border-radius: 0;
  color: #777777;
  border-width: 0 0rem 0.1875rem 0;
}

.subscribe-tab .nav-link i {
  display: inline-block;
  /* transform: scale(1.8); */
  color: #777777;
  font-size: 24px;
}

/***************subscribe-dropdown**************/
.subscribe-dropdown-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.subscribe-basic-dropdown {
  position: relative;
  margin: 0px 10px;
}

.subscribe-basic-dropdown select {
  color: #ffffff;
  width: 100%;
  font-size: 14px;
  /* background-color: #6f7072; */
  background-color: #275d77;
  border: none;
  font-weight: 100;
  padding: 0px 50px 0px 15px;
  height: 50px;
}

.subscribe-basic-dropdown span {
  position: absolute;
  top: 15px;
  right: 15px;
}

.subscribe-basic-dropdown span i {
  color: #ffffff;
}

.subscribe-basic-dropdown select:hover,
.subscribe-basic-dropdown select:focus,
.subscribe-basic-dropdown select.active {
  /* background-color: #6f7072; */
  background-color: #275d77;
  color: #ffffff;
}

.form-control:disabled,
.form-control[readonly] {
  /* background: #6f7072; */
  background: #4a7a93;
  opacity: 0.8;
}

/***************subscribe-all-card**************/

.subcribe-competitive-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  background-color: #57bad7;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
  padding: 0px 10px;
  overflow: hidden;
}

.question-mark-circle {
  top: 5px;
  right: 5px;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #2d637b;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}

.subcribe-competitive-details {
  flex: 1;
  border-bottom: 1px solid #80cee5;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.subcribe-competitive-details h4 {
  margin: 0px;
  color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.subcribe-competitive-details p {
  margin: 0px;
  color: #2d637b;
  font-weight: 500;
  padding-top: 5px;
  text-align: center;
}

.subcribe-competitive-price-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.subcribe-competitive-price-details h4 {
  margin: 0px;
  color: #2d637b;
  font-size: 20px;
}

.subcribe-competitive-price-details button {
  width: 100px !important;
  background-color: #2d637b;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 100;
  text-align: center !important;
  padding: 10px 10px;
}

.subcribe-competitive-price-details button:active,
.subcribe-competitive-price-details button:focus,
.subcribe-competitive-price-details button:hover {
  background-color: #2d637b;
  color: #ffffff !important;
}

.subscribe-modal {
  margin-top: 50px;
}

.subscribe-modal-footer {
  display: flex;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.subscribe-modal-footer button {
  width: 100px !important;
  color: #ffffff !important;
  text-align: center !important;
  font-weight: 400;
  font-size: 14px !important;
}

.subscribe-modal-footer button.cancelBtn {
  background-color: #f85a5b;
  margin-right: 10px;
}

.subscribe-modal-footer button.proceedBtn {
  background-color: #3da083;
  width: 110px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.subscribe-modal .modal-body {
  padding: 10px !important;
  color: #333;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}

.subscribe-modal .modal-footer {
  padding: 0px !important;
  border: none;
}

.subscribe-modal .modal-title {
  color: #333;
  font-size: 15px;
}

.subcribe-scholastic-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 400px; */
  /* background-color: #5dd768; */
  background-color: #bcedc0;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.subcribe-scholastic-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  /* background-color: #57c761; */
  background-color: #7fbe85;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  overflow: hidden;
}

.subcribe-scholastic-details img {
  width: 67px;
  height: 67px;
}

.subcribe-scholastic-details i {
  font-size: 60px;
  padding: 0px;
  color: #2d637b;
  height: 70px;
}

.subcribe-scholastic-details h4 {
  /* color: #ffffff; */
  color: #2d637b;
  font-size: 20px;
  margin: 0px;
  font-weight: 400;
  margin-top: 5px;
}

.subcribe-scholastic-configuration {
  flex: 1;
  height: 290px;
  padding: 10px 15px;
}

.set-scholastic-item {
  margin-bottom: 10px;
}

.set-scholastic-title h4 {
  color: #2d637b;
  /* text-transform: capitalize; */
  font-weight: 500;
  font-size: 14px;
}

.set-scholastic-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.set-scholastic-content-subscription {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}

.set-scholastic-content-lft {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.set-scholastic-content-lft_forset_only {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.set_box_width {
  /* border:1px solid #ff000000; */
  width: 95px;
}

.set-scholastic-content-lft .form-check {
  padding-right: 10px;
  /* color: #ffffff; */
  color: #2d637b;
  font-weight: 400;
}

.set-scholastic-content-lft .form-check .form-check-input {
  background-repeat: repeat-x;
  background-color: transparent;
  border-width: 1px;
  /* border-color: #ffffff; */
  border-color: #2d637b;
  width: 15px;
  height: 15px;
}

.set-scholastic-content-lft .form-check-input:checked {
  background-color: transparent;
  /* border-color: #ffffff; */
  border-color: #2d637b;
}

.set-scholastic-content-lft .form-check-input:checked[type="radio"] {
  background-image: url(/src/images/round_inside.png);
}

.set-scholastic-content-lft .form-check-input:checked[type="checkbox"] {
  background-image: url(/src/images/tick.png);
}

.set-scholastic-content-lft .form-check-label {
  margin-bottom: 0px;
  margin-top: 1px;
}

.set-scholastic-content-rght {
  width: 80px;
  background-color: #56c760;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #5ba162;
}

.set-scholastic-content-rght p {
  margin: 0px;
  color: #2d637b;
  font-weight: 500;
}

.subcribe-scholastic-price-details {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: #2d637b;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 55px;
}

.subcribe-scholastic-price-details h4 {
  margin: 0px;
  color: #57c761;
  font-size: 20px;
  font-weight: 500;
  padding-left: 5px;
}

.subcribe-scholastic-price-details button {
  width: 100px !important;
  background-color: #ffffff;
  color: #2d637b !important;
  font-size: 12px !important;
  font-weight: 500;
  text-align: center !important;
  padding: 10px 10px;
}

.subcribe-scholastic-price-details button:active,
.subcribe-scholastic-price-details button:focus,
.subcribe-scholastic-price-details button:hover {
  background-color: #ffffff;
  color: #2d637b !important;
}

/*****************proceed-left-panel*************/
.proceed-section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.proceed-calculation {
  padding: 5px 0px;
  background-color: #1d5068;
}

.proceed-set-item {
  padding: 5px 15px;
}

.proceed-set-item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.proceed-set-item-list span {
  color: #c5cfcd;
  font-size: 15px;
  font-weight: 100;
}

.proceed-set-total-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-top: 1px solid #c5cfcd;
  border-bottom: 1px solid #c5cfcd;
}

.proceed-set-total-item span {
  color: #c5cfcd;
  font-size: 16px;
  font-weight: 100;
}

.proceed-delete-procd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 0px 5px;
}

.proceed-deleteBtn {
  background-color: transparent;
  width: auto !important;
  display: flex;
  align-items: center;
}

.proceed-deleteBtn:disabled,
.proceed-deleteBtn.disabled {
  background-color: transparent;
}

.proceed-deleteBtn i.fa {
  color: #d3ae00;
  font-size: 20px;
  padding-left: 5px;
}

.proceed-deleteBtn span {
  color: #ffffff;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 100;
}

.proceed-buyBtn {
  width: auto !important;
  background-color: #d3ae00;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 400;
}

.proceed-buyBtn:disabled,
.proceed-buyBtn.disabled {
  background-color: #d3ae00;
}

.proceed-buyBtn:active,
.proceed-buyBtn:focus,
.proceed-buyBtn:hover {
  background-color: #d3ae00;
  color: #fff !important;
}

.proceed-deleteBtn:active,
.proceed-deleteBtn:focus,
.proceed-deleteBtn:hover {
  background-color: transparent;
}

.proceed-subject-view {
  flex: 1 1;
  padding: 10px 0px;
  overflow: overlay;
}

.purchase-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e1;
  padding: 10px 10px;
}

.purchase-lft-item {
  width: 65px;
  height: 65px;
  background-color: #50a9c4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-right: 10px;
}

.purchase-lft-item h4 {
  margin: 0px;
  color: #ffffff;
  line-height: 1;
  font-size: 36px;
  font-weight: 100;
  text-transform: capitalize;
}

.purchase-lft-item p {
  margin: 0px;
  color: #ffffff;
  line-height: 1;
  font-size: 11px;
  font-weight: 100;
}

.purchase-rght-item {
  flex: 1;
}

.purchase-rght-item h4 {
  margin: 0px;
  font-size: 12px;
  color: #50a9c4;
  font-weight: 400;
  line-height: 1;
}

.purchase-rght-item h5 {
  margin: 0px;
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 400;
}

.purchase-rght-item p {
  margin: 0px;
  font-size: 11px;
  color: #8c8c8c;
  line-height: 1.2;
}

.purchase-rght-item-pric {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.purchase-rght-item-pric button {
  width: auto !important;
  background-color: transparent;
  color: #8c8c8c !important;
  font-size: 11px !important;
  font-weight: 500;
  padding: 0px;
}

.purchase-rght-item-pric button:active,
.purchase-rght-item-pric button:focus,
.purchase-rght-item-pric button:hover {
  background-color: transparent;
  color: #8c8c8c !important;
}

.purchase-rght-item-pric span {
  color: #8c8c8c;
  padding: 0px 10px;
  /* font-weight: 600; */
}

.purchase-rght-item-pric .purchase-pric-value {
  color: #50a9c4;
  padding: 0px;
  font-weight: 400;
  font-size: 11px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 150px;
  position: absolute;
  top: 0;
  z-index: 99;
}

.spinner-view {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_value {
  color: #0c5576 !important;
  font-size: 14px !important;
}

.option_color {
  color: red;
}

.disable-link {
  color: #e6e6e6 !important;
  margin-left: 49px !important;
  font-size: 13px;
  padding: 6px 0;
}

.disable-link-header {
  display: flex;
  flex-direction: row !important;
  left: 18px;
  margin: 20px 0px;
}

.disable-link-header .nav-text {
  /* color: #737B8B !important; */
  color: #e6e6e6 !important;
  margin-left: 18px !important;
  font-size: 1rem;
  font-weight: 500;
}

.add-to-cart-icon-view {
  width: 24px;
  height: 23px;
}

.add-to-cart-icon-view i.fa-solid {
  color: #000000;
  font-size: 18px !important;
}

.add-to-cart-count {
  width: 16px;
  height: 16px;
  background-color: #ff6a59;
  font-size: 10px;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -1px;
}

/***************Feeback-modal****************/
.feedback_modal_container {
  height: 300px;
  overflow: auto;
  padding: 15px 15px;

  scrollbar-width: thin;
}

.feedback_item {
  display: flex;
  border: 2px solid #eeeeee;
  padding: 10px 5px 10px 10px;
  margin-bottom: 10px;
}

.feedback_btn_group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.feedback_text_container {
  flex: 1;
}

.feedback_rating_container {
  /* width: 150px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}

.feedback_cancel_btn {
  width: 120px;
  background-color: #f75a5b !important;
  height: 40px;
  border: none;
  color: #ffffff;
  border-radius: 6px;
  margin-right: 10px;
  font-weight: 500;
}

.feedback_submit_btn {
  width: 120px;
  background-color: #f0a901;
  color: #0c5576;
  height: 40px;
  border: none;
  border-radius: 6px;
  margin-left: 10px;
  font-weight: 500;
}

/* ======PDF======== */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.competitive_color {
  background-color: rgb(87, 186, 215);
}

.competitive_color_new {
  background-color: #02879b;
}

/* ////  AssessmentDetails  */

.assessment_container_parent {
  margin-bottom: 0.675rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.625rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
  /* height: calc(100% - 30px); */
  padding-bottom: 20px;
}

.assessment_container {
  /* border:1px solid red; */
  margin: 0 20px;
  padding: 10px;
  border-radius: 0.625rem;
  margin-bottom: 10px;
  /* background-color: rgb(236, 236, 236); */
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
}

.assessment_container_top {
  display: flex;
  flex: 1;
}

.assessment_question_number {
  /* padding:7px; */
  width: 60px;
  height: 30px;
  /* background-color: rgb(87, 186, 215); */
  color: #fff;
  font-weight: 500;
  border-radius: 100px;
  text-align: center;
  align-self: center;
  line-height: 30px;
}

.assessment_container_bottom {
  display: flex;
  flex: 1;
  /* flex-direction: row; */
  flex-direction: column;
}

.assessment_container_bottom_left,
.assessment_container_bottom_right {
  display: flex;
  flex: 1;
  /* max-width: 512px; */
}

.assessment_container_bottom_left {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.question_details {
  padding: 10px 0;
  font-weight: 900;
}

.answer_details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 900;
}

.assessment_container_bottom_right {
  flex-direction: column;
}

.bold_heading {
  font-weight: 900;
}

.font_green {
  color: #56c760;
  font-weight: 500;
}

.base_green {
  /* background-color: #bff5c4; */
  border: 4px solid #99f7a2;
}

.background_green {
  /* background-color: #bff5c4; */
  background-color: #bff5c4;
}

.base_red {
  /* background-color: #f9d4d4; */
  border: 4px solid #f8a0a0;
}

.background_red {
  background-color: #f8a0a0;
}

.base_grey {
  /* background-color: #ebebeb; */
  border: 4px solid #9e9e9e;
}

.background_grey {
  /* background-color: #ebebeb; */
  background-color: #9e9e9e;
}

.loader-grow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-grow>.spinner-grow {
  width: 1rem;
  height: 1rem;
  margin: 0px 5px;
}

.selectQuestionBox {
  border: 2px solid red;
}

.botton_continer_blue {
  height: 445px;
  border: 4px solid #4ba5c0;
  margin-top: 15px;
  overflow: auto;
}

.submit_container {
  margin: 5px;
  width: 97%;
  align-self: center;
}

.icon_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow_text {
  font-size: 16px;
  color: #fff;
}

.background_blue {
  background-color: #eff8fc;
}

.left_container_list {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.right_container_list {
  display: flex;
  flex: 0.2;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.exam_inside_container_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px 0 20px 0; */
  /* cursor: pointer; */
}

.insideMenu {
  padding-left: 25px !important;
}

.list_parent_container {
  /* border: 1px solid red; */
  height: 82px;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
}

.contain_container_list {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 500px;
  /* margin-bottom: 40px; */
  overflow: hidden;
}

.show_name {
  display: flex;
  flex-direction: column;
}

.user_name {
  font-size: 0.875rem;
}

.parent_container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.ntse_background {
  /* background-color: #4ba5c0; */
  background-color: #02879b;
}

.nstse_background {
  background-color: #5fc3e1;
}

.mb10 {
  margin-bottom: 10px;
}

.same_area {
  display: flex;
  flex: 1;
}

.no_data_text {
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category_list_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.option_image_responsive {
  width: auto;
  max-width: 280px;
  height: auto;
  max-height: 130px;
}

.fullScreen {
  width: 50px;
  height: 50px;
  /* border-radius: 100px; */
  /* background-color: #0c5576; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  top: 4px;
  position: relative;
}

.color_white {
  color: #fff !important;
}

.link_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.display_flex {
  display: flex;
}

.ml7 {
  margin-left: 7px;
}

.ml5 {
  margin-left: 5px;
}

.demo_spinner_position {
  top: -2px;
  position: relative;
}

.ntse-background {
  background-color: rgb(75, 165, 192);
}

.nstse-background {
  background-color: rgb(95, 195, 225);
}

.readDisplay [contenteditable] {
  pointer-events: none;
}

.ck-sticky-panel__content {
  display: none;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: none;
}

.ck.ck-editor .ck-content {
  min-height: initial !important;
  border: none !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background: none !important;
}

.ck.ck-reset.ck-editor {
  border: none !important;
}

.screec_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  z-index: 999;
}

.screec_container:after {
  content: "";
  background-color: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0.2;
}

.loader-spinner-container {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.exam_done_prent_container {
  display: flex;
  justify-content: flex-start;
}

.exam_done_mark {
  /* width:30px; */
  font-size: 20px;
  color: #56c760;
  margin-left: 10px;
  /* top:15px; */
  /* position: relative; */
  line-height: 10px;
}

.assessment.readDisplay p {
  margin: 0px !important;
}

.optLoader {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.small_text {
  font-size: 9px;
  text-align: center;
  color: #000;
  margin-left: 3px;
}

.small_text_left {
  font-size: 9px;
  text-align: left;
  color: #000;
  margin-left: 3px;
}

.remainingTime {
  font-size: 10px;
  text-align: center;
  color: #000;
}

.mandatory_color {
  color: red;
}

.register_text {
  font-size: 9px;
  color: #245c75;
}

.registration label {
  margin-bottom: 0rem !important;
}

.view_password {
  position: absolute;
  right: 23px;
  font-size: 15px;
  top: 30px;
  color: #0c5576;
}

.eye_parent_container {
  position: relative;
}

.exam_date {
  position: relative;
  top: 0px;
}

.history_box {
  max-height: 300px;
}

.displayNone {
  display: none;
}

.history_description {
  max-height: 350px;
  overflow: auto;
}

.history_description {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.history_description::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.cursorPointer {
  cursor: pointer;
}

.show_right {
  justify-content: flex-end;
}

.user_decision {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* ---------Performance -------------------*/
.performance_container {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  padding-top: 20px;
}

.performance_parent_container {
  display: flex;
  flex: 1;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */

  border-radius: 5px;
  padding: 20px;
  color: #2e637b;
}

.flex-half {
  display: flex;
  flex: 0.5;
}

.flex-2 {
  display: flex;
  flex: 2;
}

.flex-3 {
  display: flex;
  flex: 3;
}

.flex-4 {
  display: flex;
  flex: 4;
}

.chart_container {
  display: flex;
}

.flex_column {
  flex-direction: column;
}

.score_container {
  display: flex;
  justify-content: center;
  /* border: 2px solid #2e637b; */
  margin-right: 10px;
  flex-direction: column;
  /* background-color: #dbdbdb; */
  font-size: 12px;
}

.score_card_parent_container {
  display: flex;
  /* flex: 1; */
  flex-direction: column;
}

table,
th,
td {
  /* border: 1px solid #2e637b; */
  border-collapse: collapse;
  padding: 2px;
}

.tr-background {
  background-color: #98d4ef;
}

.height_100_percent {
  height: 100% !important;
}

.view_password_login {
  position: absolute;
  right: 23px;
  font-size: 15px;
  top: 35px;
  color: #0c5576;
}

/**************************profile**********************/
.profile-container {
  width: 100%;
}

.profile-header-image {
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}

.profile-header-image img {
  width: 120px;
  height: 120px;
  border-radius: 50% !important;
}

.profile-form-content {
  margin-top: 30px;
}

.profile-update-btn {
  background-color: #8b0d0e;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.profile-update-btn:hover {
  background-color: #8b0d0e;
  color: #ffffff !important;
}

.profile-update-btn .spinner-border {
  margin-left: 5px;
  border-width: 3px;
}

.profile-form-content {
  margin-bottom: 20px;
}

.custom-file-input {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #8b0d0e;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-file-input label {
  margin: 0px;
  cursor: pointer;
}

.custom-file-input label i {
  font-size: 14px;
  color: #ffffff;
}

.date-control-view {
  position: relative;
}

.date-control-view input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  appearance: none;
  border-radius: 0.625rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
  height: 45px;
}

.picker-btn {
  background-color: transparent !important;
  position: absolute;
  top: 14px;
  right: 10px;
  border: none;
}

.picker-btn i {
  color: #6e6e6e;
  font-size: 16px;
}

.email-disable:disabled {
  background-color: transparent !important;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.wh400 {
  width: 350px;
  height: 350px;
}

.wh200 {
  width: 200px;
  height: 200px;
}

.wh250 {
  width: 250px;
  height: 220px;
}

.details_text {
  padding: 10px;
  cursor: pointer;
}

.chart_details_text {
  padding: 10px;
}

.option_container {
  /* display: flex;
    flex-direction: row;
    flex: 1; */
  /* justify-content: space-around; */
  justify-content: flex-start;
  margin: 10px 0;
}

.average_table:hover {
  background-color: #e3edfe;
}

.button_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-around;
  margin: 10px 0;
}

.set-elibrary-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.set-elibrary-content-rght {
  width: 80px;
  background-color: #ffffff;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 1px 1px 2px #2e7fa3;
}

.pie_container {
  /* border:1px solid red; */
  width: 100px;
}

.pie_parent_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flex_d_col {
  flex-direction: column;
}

.marking_box {
  width: 70px;
  height: 30px;
}

.marking_box {
  width: 30px;
  height: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.correct_color {
  background-color: #00b050;
}

.incorrect_color {
  background-color: #c00000;
}

.not_attempted__color {
  background-color: #a6a6a6;
}

.marking_parent_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.graph_marking_container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.score_row:hover {
  background-color: #7caafa;
}

.laibrary-subject {
  background-color: #57c761;
  height: auto;
  padding: 15px;
}

.border-blue {
  border-color: #4ba5c0;
}

.subscription-disable {
  opacity: 0.6;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}

.w450 {
  width: 450px;
}

.w660 {
  width: 660px;
}

.borderRed {
  border: 1px solid red;
}

.text_right {
  text-align: right;
}

.below70 {
  background-color: #00b0f0;
}

.between7079 {
  background-color: #c00000;
}

.between8089 {
  background-color: #ffc000;
}

.between90100 {
  background-color: #00b050;
}

.text_underline {
  text-decoration: underline;
}

.profile-basic-dropdown {
  position: relative;
}

.profile-basic-dropdown .drop_down_arrow {
  top: 15px;
}

.profile-basic-dropdown .drop_down_arrow i {
  color: #6e6e6e;
}

.performance_score_category_parent_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  background: #fff;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
}

.performance_indi_score_container {
  width: 300px;
  height: 250px;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.score_scholatic_background {
  /* background-color: #56c760; */
  background-image: linear-gradient(to bottom, #9df5a5, #56c760);
}

.graph_img_container {
  width: 100px;
  border: 1px solid red;
  display: flex;
  /* flex:1; */
  margin: 10px;
}

.graph_img {
  width: 100%;
  justify-self: center;
}

.score_compititive_background {
  background-image: linear-gradient(to top,
      rgb(131, 212, 236),
      rgb(87, 186, 215));
}

.elibrary_background {
  background-image: linear-gradient(to top,
      rgb(255, 171, 2),
      rgb(250, 247, 20));
}

.relative_container {
  position: relative;
}

.needle_base_image_container {
  position: absolute;
  bottom: 65px;
  width: 17px;
  height: 17px;
  /* border: 2px solid white; */
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.performance_score .needle_base_image_container {
  position: absolute;
  bottom: 53px;
  width: 17px;
  height: 17px;
  /* border: 2px solid white; */
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.needle_base_image {
  width: 150%;
}

.home_needle_base_image_container {
  position: absolute;
  /* bottom: 98px; */
  bottom: 78px;
  width: 17px;
  height: 17px;
  /* border: 2px solid white; */
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_needle_base_image {
  width: 120%;
}

.GaugeChartStyle {
  border: 1px solid red;
}

.total_correct_sat {
  background-color: #00b050;
}

.total_correct_mat {
  background-color: #00c400;
}

.total_notattended_sat {
  background-color: #7f7f7f;
}

.total_notattended_mat {
  background-color: #a5a5a5;
}

.total_incorrect_sat {
  background-color: #c00000;
}

.total_incorrect_mat {
  background-color: #f9301b;
}

.font9 {
  font-size: 9px !important;
}

.speed_chart {
  height: 150px;
}

.list_container {
  display: flex;
  flex-direction: row;
}

.modal_body_height {
  /* height: 350px; */
  max-height: 350px;
  /* overflow: auto; */
  overflow-y: auto;
}

.modal_body_height {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.modal_body_height::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.assessment_icon_color {
  color: #adacac;
}

.subject_name_container {
  /* border:1px solid red;  */
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
}

.career_color {
  color: #005274 !important;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.guidance_color {
  color: #fff;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.career_card {
  background-color: #35bcf5 !important;
}

.career_color_text {
  color: #005274 !important;
}

.chart_card_parent {
  background-color: #fff;
  height: 400px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
}

.chart_card_heading {
  font-size: 15px;
  height: 20px;
  /* border:1px solid red */
}

.chart_body {
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  justify-content: flex-end;
}

.justify_self_center {
  justify-self: center;
}

.box_details {
  /* padding:2px; */
  background-color: #54823b;
  color: #fff;
  width: 50px;
  text-align: center;
  border-radius: 4px;
}

.dflex {
  display: flex;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.apexcharts-legend-marker {
  height: 10px !important;
  width: 10px !important;
}

.bottom_details {
  display: flex;
  justify-content: space-between;
}

.bottom_details div:only-child {
  align-self: flex-end;
  margin-left: auto;
}

.btn_details {
  /* padding:5px; */
  /* width: 60px; */
  height: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.back_btn_color {
  background-color: #d8d9da;
}

.text_black {
  color: #000;
}

.text_white {
  color: #fff;
}

.details_btn_color {
  background-color: #5ac378;
}

.footer .askQuestion {
  /* border: 1px solid #005274; */
  position: fixed;
  bottom: 70px;
  right: 37px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.footer .askQuestion_container {
  z-index: 9999;
}

.footer .ask_me {
  width: 80px;
  padding: 5px;
  background-color: #fff;
  position: fixed;
  bottom: 10px;
  right: 17px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #005274;
  color: #005274;
  font-size: 12px;
  z-index: 9999;
}

.fa-headset {
  color: #005274;
  font-size: 20px;
}

.modal_parent_container {
  max-height: 400px;
  overflow: auto;
  /* border:1px solid #005274; */
}

.modal_parent_container {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.modal_parent_container::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.serial_number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  border-right: 1px solid #a6a6a6;
  margin-right: 10px;
}

.search_details_parent_container {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #a6a6a6;
  border-radius: 10px;
  margin-bottom: 10px;
}

.search_details_container div {
  color: #0c5576;
}

.spinner_parent_container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0c5576;
  /* height: 60px; */
}

.spinner-container-footer {
  display: flex;
  justify-content: center;
  /* margin-top: 150px; */
  /* position: absolute; */
  top: 0;
  z-index: 99;
}

.accordion_body {
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.accordion_body strong {
  padding-right: 4px;
}

.accordion-header-icon {
  margin-right: 15px !important;
  padding-right: 10px !important;
  border-right: 1px solid #a6a6a6;
}

.image_responsive {
  /* width: 100px; */
  max-width: 500px;
}

.accordion-header-indicator {
  padding-left: 10px;
}

.history {
  padding-right: 0px !important;
}

.history .modal-dialog {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  padding: 0;
}

.history .modal-content {
  height: auto;
  min-height: 100% !important;
  min-width: 100% !important;
  border-radius: 0;
  background-color: #fca2a7;
}

.history_background {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fff;
  width: 450px;
  max-height: 500px;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 10px;
}

@media (min-width: 576px) {
  .history .modal-dialog {
    max-width: 100%;
  }
}

.history_heading {
  text-align: left;
  transform: scaleY(0.5);
  font-size: 28px;
  color: #31487e;
  font-weight: 500;
}

.history_img {
  width: 130px;
  height: 130px;
  border-radius: 200px;
  background-color: #e9eaea;
  padding: 10px;
}

.history_img img {
  width: 100%;
  float: right;
  border-radius: 50%;
  shape-outside: ellipse(160px 100px);
  /* border:solid; */
}

.history_body {
  max-height: 360px;
  overflow: auto;
  margin-bottom: 20px;
}

.history_body {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.history_body::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.close_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.image img {
  float: right;
  border-radius: 50%;
  shape-outside: ellipse(160px 100px);
  width: 130px;
  height: 130px;
  background-color: #e9eaea;
  padding: 10px;
}

/* New Login Page */

.top-bar.top-bar-light {
  background: #fff;
}

.top-bar {
  border-bottom: 1px solid transparent;
  background: transparent;
}

.top-bar .top-bar-start {
  display: flex;
}

.cover-image {
  background-size: cover !important;
  width: 100%;
  position: relative;
}

.top-bar.top-bar-light .top-bar-start .socials li a i {
  color: #6964f7;
}

.top_socials {
  display: flex;
  text-align: center;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.top_socials .social_icon {
  display: flex;
  text-align: center;
  background: none;
  border-left: #e9eaf9 1px solid;
  padding: 12px !important;
}

.top-bar-end {
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.right_border {
  border-right: #e9eaf9 1px solid;
}

.top_socials_user {
  display: flex;
  text-align: center;
  background: #fff;
  justify-content: flex-end;
  /* border-left: #e9eaf9 1px solid; */
  align-items: center;
  height: 100%;
  padding-left: 7px;
  padding-right: 7px;
}

.top_socials_user li {
  height: 100%;
  border-left: #e9eaf9 1px solid;
  padding-left: 7px;
}

.top_socials_user .social_icon {
  display: flex;
  text-align: center;
  background: #fff;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-left: 7px;
  padding-right: 7px;
}

.gap_left {
  margin-left: 7px;
}

.gap_right {
  margin-right: 7px;
}

.bg-footer {
  background-color: #12445d;
  padding-top: 20px;
  padding-bottom: 20px;
}

.arrow_size {
  font-size: 9px;
  margin-right: 2px;
  position: relative;
  top: -1px;
}

.mb5 {
  margin-bottom: 5px;
}

.footer-main h6 {
  font-size: 18px;
  margin-bottom: 1.2rem;
}

b,
strong {
  font-weight: bolder;
}

.social-icons {
  width: 35px;
  height: 35px;
  line-height: 1.7;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: flex;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-border-radius: 50%;
  border-radius: 5px;
  padding: 0;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  justify-content: center;
  align-items: center;
}

.icon_size {
  font-size: 16px;
}

.padding_right_6 {
  padding-right: 15px;
}

.h-80 {
  height: 80px;
}

.footer_store .card.green_container .btn-primary-c,
.footer_store .btn.btn-secondary,
.footer_store .btn.btn-warning,
.footer_store .btn.btn-primary,
.footer_store .btn.btn-info {
  color: #0c5576;
  width: auto;
  font-size: 14px;
  border: none;
  text-align: left;
  margin-right: 10px;
  font-weight: normal;
}

.footer-main {
  padding-top: 3rem;
  padding-bottom: 3rem;
  /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.footer-main .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ededf5;
  border-radius: 7px;
  box-shadow: none;
  margin-bottom: 1.5rem;
  width: 100%;
  height: auto;
}

.footer-main .item-card {
  overflow: hidden;
  border-radius: 7px;
}

.item-card-desc {
  position: relative;
}

.item-card-img {
  overflow: hidden;
}

.item-card .item-card-desc img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: 46px !important;
  /* padding: 1rem 0.75rem; */
}

.form-control.school_name:disabled,
.form-control.school_name[readonly] {
  background: none;
  opacity: 1;
}

.tab_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border:1px solid red; */
  background-color: #fff;
  padding: 6px;
  border-radius: 4px;
}

.both_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 20px;
  background-color: #fff;
}

.button_size {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 7px;
  margin: 5px;
  border: 1px solid #dbdcdc;
  border-radius: 4px;
}

.flex1 {
  display: flex;
  flex: 1;
}

.both_center .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border-radius: 0.3rem;
  border-color: #dee2e6 #dee2e6 #f0f0f0;
  flex: 1;
}

.both_center .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ecb033;
  flex: 1;
}

.both_center .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
}

.both_center .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: none !important;
}

.both_center .nav-tabs {
  border-bottom: none;
}

.both_center .nav-tabs li {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.both_center .nav-item {
  margin: 2px;
  border-radius: 5px;
  border-color: #dee2e6 #dee2e6 #f0f0f0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
}

.login_bg_img {
  width: 100%;
}

/* .login-main-page {
    min-height: 0px !important;
} */

.login_tab .nav-item {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 2px;
  border-radius: 10px;
  border: 1px solid #dfe0e0;
}

.login_tab .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #ecb033;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 0 1.25rem 0 var(--rgba-primary-2); */
}

.tab_button_new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border:1px solid red; */
  background-color: #fff;
  padding: 6px;
  border-radius: 14px;
  flex: 1;
}

.tab_contain {
  background-color: #fff;
  padding: 30px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 20px;
}

.login-wrapper .card {
  box-shadow: none !important;
}

.curve {
  width: 100%;
  height: 100px;
  /* background: transparent; */
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  transform: rotate(180deg);
  position: relative;
  overflow: hidden;
  background: #ecb033;
}

.curve:after {
  content: "";
  height: 100%;
  height: 100px;
  position: absolute;
  top: 130;
  background: #ecb033;
}

.FrederickatheGreat-Regular {
  font-family: "FrederickatheGreat-Regular" !important;
}

.login_subscription h1,
.download_app_container_patent h1 {
  font-weight: normal !important;
}

.login_subscription {
  width: 100%;
  height: 230px;
  /* border:1px solid red; */
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: self-start;
  padding-top: 40px;
}

.login_subscription {
  background-image: url("../images/faq-bg.jpg");
  background-position: center center;
  background-size: cover;
  /* position: relative; */
}

.login_subscription p {
  color: #5c5776;
}

.sub_input_height {
  height: 47px;
}

.login_subscription .input-group-text {
  display: flex;
  align-items: center;
  padding: 0px !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 0.75rem;
}

.login_subscription .form-control:focus {
  border-color: none !important;
}

.img_curve {
  position: absolute;
  bottom: 0px;
  height: 50px;
  width: 100%;
}

.login_subscription_patent {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 230px;
}

.download_app_container_patent {
  display: flex;
  flex: 1;
  height: 250px;
  background-color: #fff;
  margin-top: 70px;
}

.download_app_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.clv_man_log_img {
  position: absolute;
  height: 320px;
  bottom: 30px;
  left: -50px;
}

.download_app_container_patent .btn.btn-primary {
  background-color: #4e64b4;
}

.download_app_container_patent .btn.btn-primary:hover {
  background-color: #5f75c4;
}

.download_app_container_patent .btn.btn-danger {
  background-color: #ff4f4a;
}

.download_app_container_patent .btn.btn-danger:hover {
  background-color: #e7706c;
}

.download_app_container_patent .btn.btn-info {
  background-color: #36bdea;
}

.download_app_container_patent .btn.btn-info:hover {
  background-color: #7cceea;
}

.app_download_button {
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  margin-top: 20px;
  align-self: self-start;
  margin-left: 271px;
}

.download_button .btn {
  font-weight: normal;
  font-size: 14px;
}

.download_button {
  margin-right: 10px;
}

.header_position {
  background-image: url("../images/login_bg.png");
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 230px;
  display: flex;
  flex: 1;
  margin-bottom: 30px;
}

.header_logo_img {
  height: 70px;
}

.header_container {
  display: flex;
  flex: 1;
  margin-top: 20px;
  border-bottom: 1px solid #dfdede;
  height: 80px;
}

.right_side_nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
}

.right_side_nav li {
  padding: 8px;
  margin: 10px;
}

.welcome {
  text-align: center;
  font-family: "FrederickatheGreat-Regular" !important;
  font-size: 40px;
  margin-top: 20px;
  color: #51a09f;
}

.right_side_nav .nav-link.active {
  background-color: #ffcf5e;
  border-radius: 4px;
}

.sticker_base {
  width: 170px;
  padding: 5px;
  background-color: #962424;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-25deg);
  position: absolute;
  left: -46px;
  top: 12px;
  z-index: 0;
}

.sticker_base:after {
  border: 1px solid red;
}

.sticker_text {
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}

.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #ffffff;
}

.pdf_viewer_container {
  height: calc(100vh - 90px);
  border: "1px solid rgba(250, 0, 250, 1)";
}

.pdf_fullScreen {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.pdf_right_side_container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline_block {
  display: inline-block;
}

.key_value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20px;
  height: 24px;
  font-size: 17px;
  color: #000;
}

.per_button {
  padding: 2px;
  width: 60px;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #000;
}

.module_color {
  background: rgb(233, 136, 193) !important;
}

.mock_color {
  background: rgb(255, 172, 49) !important;
}

.set_color {
  background: rgb(133, 145, 203) !important;
}

.hightlight_box {
  border: 2px solid #962424;
  background-color: #f1f1f1;
}

.bottom_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.data_label {
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
}

.item_box {
  padding: 5px;
  border-radius: 3px;
  /* width: 45px; */
  margin: 2px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}

.performer_table_heading {
  font-size: 30px;
}

.modal_Header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_container {
  padding: 25px;
}

.modal_body_container {
  display: flex;
  justify-content: center;
  max-height: 350px;
  overflow: auto;
}

.modal_body_container {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: none; */
  /* Firefox */
}

.modal_body_container::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
}

.close_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f1f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.performance_table_data tr td,
.performance_table_data tr th,
.performance_table_data {
  border: none;
}

.performance_table_data {
  border-spacing: 10px;
  border-collapse: separate;
}

.performance_table_data tr td,
.performance_table_data tr th {
  width: 180px;
  height: 30px;
  padding: 10px;
  font-size: 18px;
}

.light_sky {
  background-color: #caebf9;
}

.light_sky1 {
  background-color: #caf0ff;
}

.light_sky2 {
  background-color: #d2fcfb;
}

.light_green {
  background-color: #d6f7cb;
}

.light_green1 {
  background-color: #c5ffca;
}

.light_green2 {
  background-color: #55be8a;
}

.light_red {
  background-color: #f4e8cb;
}

.light_red1 {
  background-color: #ffc0c0;
}

.light_red2 {
  background-color: #f9dcdc;
}

.light_red3 {
  background-color: #f7c79f;
}

.light_gray_1 {
  background-color: #d1d3d4;
}

.light_gray_2 {
  background-color: #f1f2f2;
}

.light_gray_3 {
  background-color: #e8e8e8;
}

.light_yellow {
  background-color: #fffcc7;
}

.light_yellow1 {
  background-color: #fcaf1c;
}

.light_blue {
  background-color: #4486c6;
}

.performance_analysis_table_data tr td,
.performance_analysis_table_data tr th,
.performance_analysis_table_data {
  border: none;
}

.performance_analysis_table_data {
  border-spacing: 10px;
  border-collapse: separate;
}

.performance_analysis_table_data tr td,
.performance_analysis_table_data tr th {
  width: 110px;
  height: 10px;
  padding: 10px;
  font-size: 12px;
}

.accordion_button {
  padding: 1rem 1.75rem;
  border: 0.0625rem solid #dbdbdb;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 0.625rem;
  transition: all 0.5s;
}

.registration_left_side {
  background: #e6e6e6;
}

.chart_parent_container {
  display: flex;
  /* border: 1px solid red; */
  /* align-items: flex-end; */
  flex-direction: row;
  justify-content: flex-start;
  overflow: auto;
  /* overflow-x: auto;
    overflow-y: auto; */
  height: 320px;
}

.col_data {
  display: flex;
  /* border: 2px solid green; */
  /* height: 100%; */
  align-items: flex-end;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  /* height: 750px; */
}

.ch_box {
  display: flex;
  width: 35px;
  height: 25px;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
}

.col_child_data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subject_name {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.ch_name {
  color: #fff;
  font-size: 9px;
}

.btn.btn-warning.blue_background {
  color: #fff;
}

.students-list table tbody tr td:last-child {
  text-align: start;
}

.students-list table tbody tr:last-child td {
  padding-bottom: 12px;
}

.subject_button {
  min-width: 145px;
  height: 30px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.fww {
  margin-left: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.action_btn {
  width: 30px;
  height: 30px;

  border-radius: 5px;
}

.view_action_btn_color {
  background-color: #17a2b8;
}

.no_subscription {
  background-color: #fff;
  border: 1px solid #bdbdbd;
}

.sub_button_select {
  border: 1px solid #245c75;
}

.payment_message_box {
  height: 550px;
}

.icon_round {
  height: 152px;
  width: 152px;
  border-radius: 50%;
}

.success_color {
  background-color: green;
}

.failure_color {
  background-color: red;
}

.payment_icon_size {
  font-size: 60px;
}

.font_green {
  color: green;
}

.font_red {
  color: red;
}

.e_library_image_container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

/* Scroll custome design */
/* width */
::-webkit-scrollbar {
  /* width: 5px; */
  height: 5px;
  width: 5px;
  border: 1px solid #d5d5d5;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6491a5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #245c75;
}

/* .scroller {
    overflow-y: scroll;
    scrollbar-color: #0A4C95 #C2D2E4;
  } */

:root {
  scrollbar-color: #245c75 grey !important;
  scrollbar-width: thin !important;
}

/* end Scroll custome design */

.status_btn {
  width: 100px;
  height: 30px;
  border-radius: 5px;
}

.chapter_col {
  white-space: pre-wrap;
}

/* .chapter_col{
    word-wrap:break-word;
    overflow-wrap:break-word;
    width:50px;
}
#student_wrapper_list table{
    table-layout: fixed;
    width:100%;
} */
#student_wrapper_list .table thead th,
#student_wrapper .table thead th {
  text-transform: none;
}

.sub_img_container {
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.education-img {
  /* max-width: 100%;  */
  height: 100%;
}

.set_parent_container {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 500px;
}

.set_container {
  width: 80px;
  height: 35px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.contain_container_top {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 500px;
  height: 100px;
  margin-bottom: 10px;
  align-items: center;
  overflow: hidden;
}

.card_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.disable_set {
  background-color: #e7e7e7;
  border: 1px solid #c6c6c6;
  color: #000;
}

.select_div_gender:focus-within,
.select_div_standard:focus-within,
.select_div_board:focus-within {
  border: 1px solid red;
}

.e_library_image {
  width: 35px !important;
  height: 35px !important;
}

.students-list table tbody tr td img {
  margin-right: 0px !important;
}

.messagesWrapper {
  display: flex;
  flex-direction: column;
  border: 1px black solid;
  height: 100px;
  overflow: scroll;
}

.left_container_performance {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.graph_img_container_performance {
  width: 100px;
  /* border: 1px solid red; */
  display: flex;
  /* flex:1; */
  margin: 3px;
}

.right_container_performance {
  display: flex;
  flex: 0.3 1;
  justify-content: flex-end;
  align-items: center;
  /* overflow: hidden; */
}

.right_image_performance {
  width: 70px;
  position: relative;
  /* left: 35px; */
  top: 2px;
  opacity: 0.2;
}

.exam_inside_container_performance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lock-screen-view {
  width: 100%;
  height: 100vh;
  background-color: #00000063;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock-screen-container {
  width: 500px;
  background-color: #ffffff;
}

.unlock-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6a59;
  color: #ffffff;
}

.unlock-btn span {
  margin-left: 5px;
}

.borderRed {
  border: 1px solid red;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a {
  border-radius: 0.625rem;
  background: #2d637b;
  color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #2d637b;
}

.view_password_reset {
  position: absolute;
  right: 23px;
  font-size: 15px;
  /* top: 37px; */
  color: #0c5576;
  /* bottom:11px; */
}

.home_image {
  max-width: 200px;
  position: "relative";
  bottom: 0;
  right: 15px;
}

.guidance_img {
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.form-control.disable_input:disabled,
.form-control.disable_input[readonly] {
  background: none;
  opacity: 1;
}

.input_value_date {
  color: #6e6e6e !important;
  font-size: 14px !important;
}

.home_page_top_container {
  display: flex;
  flex: 0.9;
  height: 270px;
  background: #7fdfc2;
  /* margin-left:15px; */
  /* margin-right:15px; */
  border-radius: 0.625rem;
  flex-direction: column;
  padding: 20px;
}

.box_container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.home_top_left_side_container {
  display: flex;
  height: 100%;
  flex: 4;
  justify-content: space-between;
  position: relative;
}

.border_box {
  position: absolute;
  top: 60px;
  display: flex;
  width: 95%;
  height: 1px;
  background-color: #fff;
  margin-left: 20px;
}

.home_top_right_side_container {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}

.single_box {
  display: flex;
  width: 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.icon_box {
  display: flex;
  width: 100%;
  height: 70%;
  justify-content: center;
  align-items: center;
}

.icon_text {
  display: flex;
  height: 30%;
  color: #227159;
  font-size: 10px;
  text-align: center;
}

.statusActive {
  color: #227159 !important;
}

.statusActiveText {
  font-weight: 500 !important;
}

.work_status {
  width: 120px;
  height: 120px;
  background-color: #0b3e3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.work_status h4 {
  color: #e7bc00;
  margin: 0px;
  font-size: 36px;
}

.work_status p {
  color: #ffffff;
  margin: 0px;
  font-size: 16px;
  font-weight: 100;
  margin-top: -10px;
}

.work_content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}

.subscription-disable-at-cart {
  opacity: 1;
  pointer-events: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  -o-filter: blur(0px);
}

@media only screen and (max-width: 768px) {
  .single_box {
    width: 80px;
    /* border:1px solid red */
  }

  .icon_box {
    width: 90%;
  }

  .border_box {
    width: 85%;
  }

  .home_top_right_side_container {
    flex: 0.999;
  }

  .work_status {
    width: 100px;
    height: 100px;
  }

  .work_content {
    padding: 0px 25px;
  }
}

@media only screen and (max-width: 625px) {
  .single_box {
    width: 68px;
    /* border:1px solid red */
  }

  .icon_box {
    width: 85%;
  }

  .border_box {
    width: 85%;
  }

  .home_top_right_side_container {
    flex: 0.999;
  }

  .work_status {
    width: 80px;
    height: 100px;
  }

  .work_content {
    padding: 0px 12px;
  }

  .work_status h4 {
    font-size: 26px;
  }

  .work_status p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .single_box {
    width: 80px;
    /* border:1px solid red */
  }

  .work_content {
    padding: 0px 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .single_box {
    width: 80px;
    /* border:1px solid red */
  }

  .work_content {
    padding: 0px 25px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 769px) {
  .single_box {
    width: 75px;
    /* border:1px solid green */
  }

  .icon_box {
    width: 85%;
  }

  .border_box {
    width: 85%;
  }

  .work_content {
    padding: 0px 25px;
  }
}

.display_img {
  width: 200px;
  height: auto;
  padding: 5px;
  border-radius: 100px;
}

.block {
  border: 2px sloid red;
}

.default_display_img {
  display: block;
  width: 200px;
  height: 200px;
  background-color: #767676;
  border-radius: 50%;
  border: 2px solid #767676;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 20px;
}

.image-change_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dlab-join-card {
  position: relative;
  /* margin-top: 1rem; */
  margin-top: 0px;
  z-index: 1;
  box-shadow: 0 0 1.875rem var(--rgba-secondary-1);
  background-color: #2e637b;
}

.spinner_performance_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.icon_description {
  font-size: 11px;
}

.screen_height {
  height: calc(100vh - 200px);
}

.label_note {
  font-size: 11px;
  font-weight: 300;
  font-style: italic;
}

.user_img_size {
  font-size: 20px;
  color: #ffffff;
}

.add_container {
  width: 40px;
  height: 40px;
  background-color: #005274;
  border-radius: 50%;
  align-self: center;
  position: relative;
}

.plus_container {
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.slider_container {
  width: 100%;
}

.submit_background {
  background-color: #245c75;
  color: #fff;
  font-size: 18px;
}

.img_rotate_btn {
  width: 80px;
  padding: 3px;
  border-radius: 5px;
  background-color: #2c68fe;
  color: #fff;
  font-size: 12px;
}

.registration .css-13cymwt-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  /* border-color: hsl(304, 89%, 46%); */
  border-radius: 0.65rem;
  /* border-style: solid; */
  /* border-width: 1px; */
  box-sizing: border-box;
  border: 1px solid #dbdbdb;
  line-height: 2.4;
  padding: 2px 0 2px 2px;
}

/* .registration .css-t3ipsp-control{
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    // border-color: #2684FF; 
    border-radius: 0.65rem;
    // border-style: solid;
    // border-width: 1px;
    // box-shadow: 0 0 0 1px #2684FF;
    box-sizing: border-box;
    // border: 1px solid #DBDBDB;
    border: 1px solid #ff0000;
    line-height: 2.4;
    padding: 2px 0 2px 2px;
} */
.registration .css-t3ipsp-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  /* // border-color: #2684FF; */
  border-radius: 0.65rem;
  /*  // border-style: solid; */
  /* // border-width: 1px;  */
  box-shadow: 0 0 0 0 #d70b66 !important;
  box-sizing: border-box;
  /* border: 1px solid #DBDBDB; */
  border: 1px solid #ff0000;
  line-height: 2.4;
  padding: 2px 0 2px 2px;
}

.registration .css-1dimb5e-singleValue {
  color: #0c5576 !important;
  font-size: 14px !important;
}

.registration .css-1xc3v61-indicatorContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: #dbdbdb;
  padding: 8px;
  box-sizing: border-box;
}

.registration .css-1xc3v61-indicatorContainer:hover {
  color: #0c5576;
}

.registration .css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  width: 17px;
}

.registration .css-t3ipsp-control:hover {
  border: 1px solid #dbdbdb;
}

.card_wrap {
  flex-wrap: wrap;
  display: flex;
  flex: 1;
}

.e_library_text {
  text-transform: lowercase;
}

.no_inter_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.font_100 {
  font-size: 100px;
  color: #1d5068;
}

.info_box {
  position: absolute;
  width: 220px;
  height: 50px;
  top: 20px;
  right: 15px;
  background-color: yellow;
  z-index: 3;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2px;
  font-size: 9px;
  font-weight: 500;
  color: #0c5576;
}

.blue_color {
  color: #0c5576;
}

.expire_color {
  color: red;
}

.select_text:active,
.select_text:focus,
.select_text:focus-visible {
  border-bottom: 1px solid #0c5576 !important;
}

.select_button:active,
.select_button:focus,
.select_button:focus-visible {
  border: 1px solid #ff0000 !important;
}

.left_width {
  width: 250px;
}

.middle_width {
  width: 10px;
}

.right_width {
  width: 250px;
}

.border_bottom {
  border-bottom: 1px solid #0c5576;
}

.parent_container_width {
  width: 440px;
}

/* .assessment_container_bottom_right mjx-math {
    white-space: pre-wrap !important;
    border:1px slid red;
} */
/* .toolTips{
    max-width:150px;
    padding:5px;
    background-color: #898989;
    position:absolute;
    left:42px;
    top:0px;
    border-radius: 2px;
    font-weight: bold;
    z-index: 999;
} */

.toolTipsLessThenSix {
  display: flex;
  /* min-width:100px; */
  max-width: 150px;

  position: absolute;
  left: 42px;
  top: 0px;
  border-radius: 2px;
  font-weight: bold;
  z-index: 99999;
  flex-direction: column;
}

.toolTips {
  display: flex;
  /* min-width:100px; */
  max-width: 150px;
  /* height:100px; */
  /* border-radius: 50%; */
  /* border:1px solid red; */
  /* padding:5px; */
  /* background-color: #fff; */
  position: absolute;
  left: 42px;
  top: 0px;
  border-radius: 2px;
  font-weight: bold;
  z-index: 99999;
  flex-direction: column;
}

.top_container {
  width: 100%;
  padding: 4px;
  /* height:50px; */
  background-color: #d5d2d2;
  border-radius: 5px;
  border: 1px solid #7f7f7f;
  font-weight: 500;
  color: #000;
  font-size: 11px;
}

.chart_parent_container .col_data:last-child .toolTips,
.chart_parent_container .col_data:nth-last-child(2) .toolTips {
  left: auto;
  right: 40px;
}

.chapter_panel {
  position: relative;
}

mjx-mspace {
  display: block;
}

.assessLeftPanelWidth {
  width: 130px;
  /* border:1px solid red; */
  flex: 0 0 130px;
}

.bottom_border {
  border: 1px solid #d3d3d3;
}

.marginLeftFifty {
  margin-left: 50px;
}

.marginLeft-90 {
  margin-left: 90px;
}

.marginLeft-15 {
  margin-left: 15px;
}

.data_label_parent_container {
  /* width: 538px; */
  overflow-x: auto;
  margin-bottom: 7px;
}

/* .fa {
    font-family: 'Lato', 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    font-weight: 900;
    color: #3a9c7d;
} */

.done_color {
  color: #1a9525;
}

.current_color {
  color: #ffffff;
}

.done_color {
  color: #ff0000;
}

.border_radious_50 {
  border-radius: 50%;
}

.question_heading ul li {
  list-style-type: disc;
}

.question_heading ol li {
  list-style-type: decimal;
}

.assessment_container ul li {
  list-style-type: disc;
}

.assessment_container ol li {
  list-style-type: decimal;
}

.comming_soon_logo {
  width: 300px;
}

.left_box_width {
  width: 30px;
}

.left_box {
  width: 30px;
  /* border:1px solid red */
}

.right_box {
  /* width:350px; */
  width: 100%;
  /* border:1px solid green */
}

.subcribe-integrated-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  background-color: #2e999f;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
  padding: 0px 10px;
  overflow: hidden;
}

.integrated_background {
  background-color: #2e999f;
}

.instructionParentBox {
  width: 100%;
  height: 100%;
  /* border:1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.instructionParentInsideBox {
  width: 300px;
  height: 220px;
  border: 2px solid rgb(174, 171, 171);
  padding: 10px;
  border-radius: 10px;
}

.instructionScholasticParentInsideBox {
  width: 360px;
  height: 305px;
  border: 2px solid rgb(174, 171, 171);
  padding: 10px;
  border-radius: 10px;
}

.instructionindividualBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.color_box {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.color_nine {
  background-color: #31c5f4;
}

.color_eight {
  background-color: #5bba47;
}

.color_seven {
  background-color: #f1c431;
}

.color_six {
  background-color: #982626;
}

.instructionColorInstruction {
  font-size: 12px;
}

.interm_count_exam_done_mark {
  /* width:30px; */
  font-size: 14px;
  color: #f38f76;
  /* margin-left: 10px; */
  /* top:15px; */
  /* position: relative; */
  line-height: 10px;
}

.interm_count_exam_color {
  color: #2d6179;
}

.tableFixHead {
  overflow-y: auto;
  /* make the table scrollable if height is more than 200 px  */
  height: 500px;
  /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
  position: sticky;
  /* make the table heads sticky */
  top: 0px;
  /* table head will be placed from the top of the table and sticks to it */
}

.thableHeaderBackground {
  background-color: #fff;
}

.checkbox_position {
  position: relative;
  top: -4px;
}

.precent_text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.basic-dropdown {
  position: relative;
}

.h_padding {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
}

.reset-btn-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 15px;
}

.reset_btn_background {
  background-color: #00b0f0 !important;
  color: #fff !important;
}

.overall_sub_image {
  width: 60px;
  height: 60px;
  background-color: #fff;
  padding: 4px;
  border-radius: 10px;
  margin-left: 10px;
}

.overall_sub_image_competitive {
  /* width:40px; */
  width: 50px;
  height: 60px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-left: 5px;
}

.divider {
  padding: 8px;
  text-align: left;
  border-bottom: 11px solid #ff0000;
}

@media (min-width: 992px) {
  .dashboard .modal-lg {
    max-width: 859px;
  }
}

.line_break {
  white-space: initial;
  line-height: 17px;
}

.assessment_container_bottom_right img {
  max-width: 500px;
}

.pagination .disabled {
  visibility: hidden;
}

.wrap_text {
  width: 100%;
  /* overflow: hidden; */
  overflow-x: auto;
  overflow-y: hidden;
  word-wrap: break-word;
}

.resize {
  border: 1px solid black;
  overflow: auto;
}

.resize.vertical {
  resize: vertical;
}

.parent_container_new {
  position: relative;
}

#answer_option_panel {
  position: absolute;
  width: 100%;
  padding-left: 4px;
  /* height: 96px; */
  min-height: 150px;
  max-height: 350px;
  right: 0;
  bottom: 0;
  background-color: #fff;
  overflow: auto;
  border-radius: 10px;
}

#answer_option_panel_competitive {
  position: absolute;
  width: 100%;
  padding-left: 4px;
  /* height: 96px; */
  min-height: 150px;
  max-height: 350px;
  right: 0;
  bottom: 0;
  background-color: #eff8fc;
  overflow: auto;
  border-radius: 10px;
}

#resize {
  background-color: #ccc;
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  /* cursor: w-resize; */
  cursor: row-resize;
}

.subscribe-modal.chapter_test .modal-dialog.modal-lg .modal-content {
  width: 900px !important;
}

.subject_name_new {
  word-wrap: break-word;
  /* width: 100px; */
  padding: 20px;
  max-width: 150px;
}

/* reasone math line breal */
.assessment_reasons mjx-mspace {
  display: block;
  height: 10px;
}

.assessment_reasons mjx-assistive-mml {
  width: 1px;
  height: 1px;
}

/* End reasone math line breal */

.right_box_width {
  width: 130px;
  margin-left: 30px;
}

/* mouse Scrolling */

.table_parent_container {
  display: flex;
  position: relative;
}

#left,
#right {
  position: absolute;
  top: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.1); */
  pointer-events: all;
  /* opacity: 0; */
  width: 10%;
  transition: opacity 0.25s ease-in;
}

#left:hover,
#right:hover {
  opacity: 1;
}

#left {
  left: 0;
}

#right {
  right: 0;
}

.chart_bottom {
  align-items: flex-end;
}

.chart_heading {
  font-size: 15px;
  font-weight: 400;
  margin-right: 10px;
  color: #000000;
}

.error-page .error-text {
  font-size: 3.375rem;
  line-height: 1;
}

.color_clt {
  background: rgb(56, 81, 171);
}

.color_mol {
  background: rgb(246, 87, 170);
}

.color_mcl {
  background: rgb(244, 115, 36);
}

.error-page h4,
.error-page .h4 {
  font-size: 1.5rem;
  margin-bottom: 0.3125rem;
}

.padding20 {
  padding: 20px;
}

.messageContainer {
  width: 200px;
}

.userImageSize {
  width: 50px;
  height: 50px;
}

.text_center_in_page {
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_image_text_container {
  /* border:1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_image_text {
  text-align: center;
}

.test_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.no_image_container {
  width: 50px;
  height: 50px;
  background-color: #fff;
  /* padding: 10px; */
  border-radius: 10px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End mouse Scrolling */

.top-heading-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subsciption_course_valitidy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.subsciption_course_valitidy span {
  font-size: 15px;
}

.subsciption_course_valitidy h4 {
  font-size: 15px;
  color: #d7a300;
  font-weight: 400;
}

.disable_cart_btn {
  background-color: #b66865 !important;
  cursor: default !important;
}

.exam-academic-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.exam-academic-label {
  padding-right: 5px;
}

.profile-view .react-datepicker-wrapper {
  width: 100%;
}

.profile-form-control:disabled {
  background-color: #ffffff !important;
}

/* .profile-form-control option:disabled {
  color: gray;
  background-color: #f0f0f0;
} */
.archive-score-container {
  width: 100%;
}

.archive-score-btn-view {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.archive-score-btn-view button {
  width: 300px;
  height: 60px;
  background-color: #8e9090;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 18px;
}

.archive-score-btn-view button:disabled {
  background-color: #91abb6;
  cursor: default;
}

.active-archive {
  background-color: #2e637b !important;
}

.archive-score-standard {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/************************Register-Container*****************/
.register-container {
  background-color: #d8b002;
  padding: 40px 20px;
}

.register-container h4 {
  text-align: center;
  color: #000000;
  font-size: 24px;
  padding-bottom: 20px;
}

.register_btn_container {}

.register_btn {
  background-color: #962424;
  color: #ffffff;
  margin-bottom: 20px;
}

.register-success-container {
  width: 100%;
  height: 100%;
}

.register-success-view {
  text-align: center;
  /* background-color: #ffffff; */
  background-color: #E7BB00;
  padding: 20px 10px;
}

.register-success-view p,
.register-success-view span {
  color: #000000;
}

.success-login-here {
  padding-top: 10px;
  text-align: center;
}

.success-login-here p a {
  color: #000000;
  font-size: 16px;
  text-decoration: underline;
  color: blue;
  font-weight: 600;
}

.relative-class {
  position: relative
}

.icon-position {
  position: absolute;
  justify-content: flex-end;
  align-items: center;
}

.info-container p {
  color: #000000;
  font-size: 10px;
  margin-bottom:0;
}

.demo-info-container p{
  color: #ff0000;
  font-size: 10px;
  margin-bottom:0;
}

.download-app-button-container{
  display: flex;
  width:100%;
  justify-content: center;
  align-items: center;
}
.download-app-button {
  width:150px;
  height:auto;
  text-align: center;
}
.app-download-page-container{
position: absolute;
top:60px;
}
.app-download-co-logo{
  width:200px;
  height:auto;
}